/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');


// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700;900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');



:root {

    --ion-font-family: 'Montserrat';
    // font-weight: 500;
    /** primary **/
    }


    ion-title {
        font-size:large;
        text-align: center;
        // padding: 0 78px 0;
        // position: absolute;
        // top: 0;
        // left: -10%;
        // width: 120%;
        height: 100%;
        font-weight:900;
        color:rgb(18, 18, 18);
        // font-size: 16px;
      }
    
      ion-button {
        text-transform: none;
        --border-radius: 10px!important;
        font-size:large;
        font-weight: 900;  
      }


      ion-modal {
        --border-radius: 16px;
        --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      }



      ion-radio {
        --border-radius: 4px;
        --inner-border-radius: 4px;
        --color: #ddd;
        --color-checked: white;
      }
      
      ion-radio.ios::part(container) {
        width: 20px;
        height: 20px;
        background-color:#F38031;

        border: 2.5px solid #ddd;
        border-radius: 4px;
      }
      
      .radio-checked.ios::part(container) {
        border-color: white;
        background-color:#F38031;

      }


/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
