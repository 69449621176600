// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {




  /** primary **/
	--ion-color-primary: #180c9c;
	--ion-color-primary-rgb: 24,12,156;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #150b89;
	--ion-color-primary-tint: #2f24a6;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

	--ion-color-puerta: #047cfc;
	--ion-color-puerta-rgb: 4,124,252;
	--ion-color-puerta-contrast: #ffffff;
	--ion-color-puerta-contrast-rgb: 255,255,255;
	--ion-color-puerta-shade: #046dde;
	--ion-color-puerta-tint: #1d89fc;

}


:root {
	--ion-color-instagram: #047cfc;
	--ion-color-instagram-rgb: 4,124,252;
	--ion-color-instagram-contrast: #ffffff;
	--ion-color-instagram-contrast-rgb: 255,255,255;
	--ion-color-instagram-shade: #046dde;
	--ion-color-instagram-tint: #1d89fc;
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram) !important;
  --ion-color-base-rgb: var(--ion-color-instagram-rgb) !important;
  --ion-color-contrast: var(--ion-color-instagram-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-instagram-shade) !important;
  --ion-color-tint: var(--ion-color-instagram-tint) !important;
}



:root {


	--ion-color-didibotones: #F38031;
	--ion-color-didibotones-rgb: 243,128,49;
	--ion-color-didibotones-contrast: #ffffff;
	--ion-color-didibotones-contrast-rgb: 0,0,0;
	--ion-color-didibotones-shade: #d6712b;
	--ion-color-didibotones-tint: #f48d46;
}

.ion-color-didibotones {
  --ion-color-base: var(--ion-color-didibotones) !important;
  --ion-color-base-rgb: var(--ion-color-didibotones-rgb) !important;
  --ion-color-contrast: var(--ion-color-didibotones-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-didibotones-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-didibotones-shade) !important;
  --ion-color-tint: var(--ion-color-didibotones-tint) !important;
}
